<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import moment from 'moment'

export default {
  name: 'timedDrawDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        stockNum: null,
        type: '1',
        height: ''
      },
      mainBodyType: [],
      stockNumCopy: 0,
      ruleDTOList: []
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/farmActivityDrawPrize/detail?id=${id}`
        })
        .then(res => {
          this.ruleDTOList = res.prizeRuleList
        })
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'name',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '背景色（例：#000000）',
            type: 'input',
            cols: 12,
            key: 'bgColor',
            maxLength: 7,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '转盘距离下面模块高度（PX）',
            type: 'inputNumber',
            cols: 12,
            key: 'height',
            props: {
              addonAfter: 'PX',
              min: 0
            },
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '活动开始时间',
            type: 'row',
            cols: 12,
            gutter: 10,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ],
            children: [
              {
                label: '开始日期',
                type: 'datePicker',
                cols: 12,
                key: 'startTime',
                props: {
                  mode: 'date',
                  format: 'YYYY-MM-DD',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  placeholder: '请选择开始日期'
                },
                rules: [
                  {
                    required: true,
                    type: 'string'
                  }
                ]
              },
              {
                label: '开始时间',
                type: 'timePicker',
                cols: 12,
                key: 'startTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  allowClear: false,
                  placeholder: '请选择开始时间'
                },
                rules: [
                  {
                    required: true,
                    type: 'string'
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: '抽奖页底图（尺寸750 高度不限）限制1张',
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'bgImg'
            // rules: [
            //   {
            //     required: true,
            //     message: '请上传图片',
            //     type: 'string'
            //   }
            // ]
          }
        ]
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'cardForm',
        title: '抽奖规则',
        cols: 24,
        data: [
          {
            type: 'table',
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            showHeader: false,
            onAddData: () => {
              return {
                isEdit: true,
                content: '',
                title: ''
              }
            },
            columns: [
              {
                dataIndex: 'title',
                align: 'left',
                title: '简介',
                customRender: (text, records, index) => {
                  const onInput = data => {
                    records.title = data.target.value
                  }
                  return records.isEdit ? <a-input placeholder="" onChange={onInput} value={text} /> : <div>{text}</div>
                }
              },
              {
                dataIndex: 'content',
                align: 'left',
                title: '商家信息',
                customRender: (text, records, index) => {
                  const onInput = data => {
                    records.content = data.target.value
                  }
                  return records.isEdit ? <a-input placeholder="" onChange={onInput} value={text} /> : <div>{text}</div>
                }
              },
              {
                dataIndex: 'action',
                align: 'center',
                width: '10%',
                title: '操作',
                tableType: 'editDelete',
                onSave: (text, records) => {
                  records.isEdit = false
                }
              }
            ],
            dataSource: this.ruleDTOList
          }
        ]
      }
    },
    getForm4() {
      return {
        title: () => (
          <div>
            <span style="color:#f00">*</span>奖品
          </div>
        ),
        type: 'cardForm',
        data: [
          {
            name: '类型',
            type: 'select',
            cols: 6,
            key: 'type',
            typeData: [
              //   {
              //     name: '优惠券',
              //     value: '0'
              //   },
              //   {
              //     name: '兑物券',
              //     value: '2'
              //   },
              //   {
              //     name: '余额券',
              //     value: '3'
              //   },
              {
                name: '数币红包',
                value: '1'
              }
            ]
          },
          {
            name: '名称',
            type: 'dropDownInput',
            cols: 6,
            key: 'relationName',
            onInputSearch: this.onMainBodySearch,
            onMenuClick: this.onProductMenuClick,
            typeData: this.mainBodyType,
            valueKey: 'relationId',
            inputProps: {
              addonBefore: <a-icon type="search" />
            }
          },
          {
            name: '剩余库存',
            type: 'text',
            cols: 2,
            value: this.stockNumCopy ?? 0
          },
          {
            name: '活动库存',
            type: 'inputNumber',
            cols: 10,
            key: 'stockNum',
            props: {
              min: 0
            }
          },
          {
            name: '报名开始',
            type: 'row',
            cols: 12,
            gutter: 10,
            children: [
              {
                label: '开始日期',
                type: 'datePicker',
                cols: 16,
                key: 'signupStartTime',
                props: {
                  mode: 'date',
                  format: 'YYYY-MM-DD',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  placeholder: '请选择发放开始日期'
                }
              },
              {
                label: '开始时间',
                type: 'timePicker',
                cols: 8,
                key: 'signupStartTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  allowClear: false,
                  placeholder: '请选择发放开始时间'
                }
              }
            ]
          },
          {
            name: '报名结束',
            type: 'row',
            cols: 12,
            gutter: 10,
            children: [
              {
                label: '开始日期',
                type: 'datePicker',
                cols: 16,
                key: 'signupEndTime',
                props: {
                  mode: 'date',
                  format: 'YYYY-MM-DD',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  placeholder: '请选择发放开始日期'
                }
              },
              {
                label: '开始时间',
                type: 'timePicker',
                cols: 8,
                key: 'signupEndTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  allowClear: false,
                  placeholder: '请选择发放开始时间'
                }
              }
            ]
          },
          {
            name: '开奖时间',
            type: 'row',
            cols: 12,
            gutter: 10,
            children: [
              {
                label: '开始日期',
                type: 'datePicker',
                cols: 16,
                key: 'drawOpenTime',
                props: {
                  mode: 'date',
                  format: 'YYYY-MM-DD',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  placeholder: '请选择发放开始日期'
                }
              },
              {
                label: '开始时间',
                type: 'timePicker',
                cols: 8,
                key: 'drawOpenTime',
                props: {
                  format: 'HH:mm',
                  valueFormat: 'YYYY-MM-DD HH:mm:ss',
                  allowClear: false,
                  placeholder: '请选择发放开始时间'
                }
              }
            ]
          }
        ]
      }
    },
    async onMainBodySearch(value = '') {
      if (!this.detail.type) {
        this.$message.warning('请先选择类型')
        return
      }
      const searchResult = await api.command.search.call(this, {
        url: `/api/base/farmCouponRule/relationList?name=${value ?? ''}&couponType=${this.detail.type}&busId=${this
          .detail.id ?? ''}`
      })
      this.mainBodyType = searchResult.map(el => {
        return {
          ...el,
          name: el.couponName,
          value: el.id
        }
      })
    },
    onProductMenuClick(data) {
      const { residueStock } = data
      this.stockNumCopy = residueStock
      this.$forceUpdate()
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            const { signupStartTime, signupEndTime } = data
            if (signupStartTime && signupEndTime) {
              if (!moment(signupStartTime).isBefore(signupEndTime)) {
                this.$message.warning('报名结束时间应在开始时间之后！')
                return
              }
            }
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: `/farmActivityDrawPrize/${data.id ? 'update' : 'save'}`,
                params: {
                  ...data,
                  ruleDTOList: this.ruleDTOList,
                  startTime: moment(data.startTime).format('YYYY-MM-DD HH:mm:00'),
                  signupStartTime: moment(data.signupStartTime).format('YYYY-MM-DD HH:mm:00'),
                  signupEndTime: moment(data.signupEndTime).format('YYYY-MM-DD HH:mm:00'),
                  drawOpenTime: moment(data.drawOpenTime).format('YYYY-MM-DD HH:mm:00')
                },
                isPost: false
              })
              .then(() => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          onClick: () => {
            setTimeout(() => {
              this.$router.push('/marketingCenter/timedDraw')
            }, 100)
          }
        }
      ]

      const right = [
        {
          name: '删除',
          type: 'danger',
          isPop: true,
          popTitle: '确认是否删除吗?',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: '/farmActivityDrawPrize/delBatch',
              params: { idList: [this.detail.id] },
              isPost: false
            })

            setTimeout(() => {
              this.$router.push('/marketingCenter/timedDraw')
            }, 100)
          }
        }
      ]

      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), this.getForm3(), this.getForm4()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/ .ant-table-footer {
  padding-left: 0;
  padding-right: 0;
}

/deep/.ant-table-fixed {
  padding-left: 0px !important;

  td {
    padding-left: 0;
  }
}
</style>
